@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.App {
    text-align: center;
    font-family: Montserrat, sans-serif;
}

.footer {
    padding: 2.5%;
    background-color: #343434;
    text-align: center;
    color: #ffffff;
    font-size: medium;
}
