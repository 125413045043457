@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.navbar {
    z-index: 20;
    position: fixed;
    display: flex;
    background-color: #ffffff;
    padding: 1% 4%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    align-items: center;
    height: 13vh;
    max-width: 100vw;
    width: 100vw;
    
    .ant-anchor {
        display: flex;
    }

    .ant-anchor-link {
        margin-right: 50px;
        font-style: bold;
    }
    
    .ant-anchor-ink {
        display: none;
    }
  
    .navbar-image {
        // aspect-ratio: auto 185/77;
        float: left;
        margin: 0 auto;
        margin-left: 0px;
    }
  
    a {
        cursor: pointer;
        font-weight: 700;
        font-style: bold;
        min-height: 21px;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        line-height: 19.5px;
        text-decoration: none;
        color: #000000;
        margin-right: auto;
    }

    a:hover {
        color: #E6B31E !important ;
    }

    .ant-anchor-link-title.ant-anchor-link-title-active {
        color: #e6b31e !important;
    }    
}

.category-title {
    margin: 4% 2% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-evenly !important;

    .head-title {
        width: 40%;
        text-align: center !important;
        border-bottom: 5px solid #E6B31E !important;
        line-height: 0.1em !important;
        margin: 2% 8% !important;
        
        .head-title-span {
            font-size: xx-large;
            font-weight: 500 !important;
            color: #000000 !important;
            background: #fff !important;
            padding: 1% 1% !important;
        }
    }
}


// Styling for web page
@media screen and (min-width: 767px) {
    .mobile-visible {
        display: none;
    }
}

// Styling for mobile
@media screen and (max-width: 767px) {
    .navbar {
        height: 10vh;
        width: 100vw;
        // background-color: orange;
    }

    .breadcrumb {
        display: none;
    }
    
    .head-title {
        min-width: 100% !important;

        .head-title-span {
            background-color: #E6B31E;
            font-size: x-large !important;
        }
    }
    
    .mobile-visible{
        left: 30vw;
        position: relative;
    }

    .dropdown-menu {
        font-size: large;
        top: 3vh;
        left: 3vw;
        background: rgba(0, 0, 0, 0.85) !important;
        color: #ffffff !important;
        width: 75vw;
        text-align: center !important;
  
        li {
            font-size: x-large !important;
        }
    }

    .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover {
        background-color: #E6B31E;
    }
}
