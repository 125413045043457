// Who We Are
.banner {
    background-image: url('../../assets/images/samparkbindu 3.png');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 12.5rem;

    .alertUpdate{
        top: -7.51rem;
        z-index: 0;
        margin:0rem;
        padding:0rem;
    }

    .ant-image {
        opacity: 0;
    }
    
    .scroll-title {
        font: 'Montserrat';
        font-weight: 700;
        font-style: 'normal';
        font-size: '1rem';
        line-height: '1.219rem';
        color: #ffffff;
      
    }

    .bannerImage{
        margin-top: 5%;
        width:50%; 
    }

      .wrapper{
        display:flex;
        justify-content:center;
        align-items:center;
        height:2.5rem;
        // background:blue;
        overflow: hidden;
        margin-top: 10px;
        .dynamic-txts{
            // left:-50px;
            position: relative;
            top:0.688rem;
            height:2.5rem;
            overflow: hidden;
            li{
                list-style: none;
                font-size:1.25rem;
                position: relative;
                top:0;
                animation: slide 6s steps(4) infinite;
                @keyframes slide{
                    100%{
                        top:-9.375rem;
                    }
                }
                span{
                    position: relative;
                }
                span::after{
                    content:"";
                    position: absolute;
                    height:100%;
                    width:100%;
                }
            }
        }
      }
}

#whatweoffer{
    text-align: start;
    scroll-margin: 15vh;

    .what-we-offer-title {
        color: #e6b31e !important;
        text-align: start;
    }
}

#team{
    button {
        background: linear-gradient(180deg, #f7682b 0%, #ff3e3e 100%);
        margin-bottom: 2rem;
        box-shadow: 0rem 0.25rem 1.25rem rgba(246, 104, 43, 0.3);
        border-radius: 1.25rem;

        a {
            color: #fff;
        }
    }
}
#contact {
    position: relative;
    scroll-margin: 15vh;

    .contact-us-info {
        font-size: 1.2rem;
        text-align: left;

        .anticon {
            font-size: x-large;
            color: #f5a31a;
            padding: 1rem 1rem;
        }

        .contact-address {
            position: relative;
            bottom: 2.75rem;
            left: 3.35rem;
        }
        .mapouter{
            position:relative;
            top:-3.125rem;
            left:0.938rem;
            width:100%;
            height:13.188rem;
            border-radius: 0.625rem;
            border: 0.063rem solid #F5A31A;
            overflow-y: hidden;
            .gmap_canvas{
                overflow:hidden;
                background:none!important;
                width:100%;
                height:13.188rem;
                .gmap_iframe{
                    height:13.188rem !important;
                }
            }
        }
    }

    .write-feedback {
        background-color: #fbfbfb;
        padding: 1rem;
        float: 'right';
        margin-bottom: 2rem;

        .feedback-form {
            padding: 1.5rem;
            text-align: start;

            .write-feedback-title {
                text-align: center !important;
            }
        }
    }
}

#updates {
    scroll-margin: 15vh;
    .update-wrapper{
        margin-top: 0.938rem;
        .cardWrapper{
            height: 100%;
            .update-card{
                height: 20rem; 
                overflow-Y:auto;
                .cardContent{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-top: 1.875rem;
                    margin-bottom: 1.875rem;
                    .cardDescription{
                        display:flex;
                        flex-direction: row;
                        justify-content:space-evenly;
                        align-items:center;
                        .innerText{
                            margin-left:0.938rem;
                            text-align:start;
                        }
                        .cardImage{
                            padding-left: 0.938rem;
                            height: 40vh;
                            width: 40vw;
                            object-fit: fill;
                        }
                    }
                } 
            }
        } 
    }
}

#articles{
    scroll-margin:15vh;
    .articleContainer{
        margin-top:-0.625rem;
        width: 100vw;
        // background: blue;
        height: fit-content;
        display:flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .articleBorder{
            padding:0.313rem;
            width:90vw;
            height:fit-content;
            // background:#E6B31E;
            border: 1px solid #E6B31E;
            border-radius:0.625rem;
            margin-top: 0.625rem;
            margin-bottom: 0.625rem;
            .articleTitle{
                font-size: 1.563rem;
            }
            .articleContent{
                width:90vw;
                padding: 0.625rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                // background:orange;
                .articleDescription{
                    text-align:start;
                    width:40vw;
                }
                .articleImage{
                    border-radius:0.625rem;
                    height: 30vh;
                    width: 30vw;
                }
            }
        }
    }
}

.partners {
    padding-top: 5rem;
    padding-bottom: 1rem;
    width: 100vw;
    background-color: #f6f6f4;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0rem;
    scroll-margin: 2.8rem;

    .blue-box {
        background: linear-gradient(180deg, #42d4f4 0%, #4285f4 100%);
        border-radius: 0.938rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.6rem;
        height: 4.6rem;
    }

    .partnerTitle {
        text-align: center;
        color: #102e44;
        font-size: 3rem;
        line-height: 3.688rem;
        font-family: 'Montserrat';
        margin-top: 1rem;
        font-weight: 700;

        .first_word {
            font-weight: 400;
        }
    }

    .partners-phone-display {
        display: none;
    }
    .web-visible-partners {
        div.ant-carousel {
            width: 70rem;
            height: fit-content;
            div.inside-carousel {
                width: 70rem !important;
                display: flex !important;
                height: 300px;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                // background-color: yellow;
                img {
                    // background-color: red;
                    height: 12rem;
                    width: 12rem;
                    object-fit: contain;
                }
            }
            span.anticon.anticon-left.slick-arrow.slick-prev {
                font-size: 20px;
                color: #031118;
                &:hover {
                    color: #00b2ff !important;
                }
            }
            span.anticon.anticon-right.slick-arrow.slick-next {
                font-size: 20px;
                color: #031015;
                &:hover {
                    color: #00b2ff !important;
                }
            }
        }
    }

    .grid-container {
        margin-top: 4rem;
        display: grid;
        height: fit-content;
        width: 95%;
        grid-template-columns: repeat(5, 20%);
    }

    .grid-item {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 80%;
            height: 80%;
            object-fit: contain;
        }
    }

    .grid-item:hover {
        background-color: #fff;
        box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.16);
    }
    
}
.team_back_button {
    padding-top: 1rem;
    .gradient-icon {
        color: #0c0b0b;
    }

    .gradient-text {
        background: #ffffff;
        background-clip: text;
        color: black;
        font-size: 1rem;
        font-weight: 600;
        font-family: 'Montserrat';
    }

    a {
        position: absolute;
        left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    }
}

#teamextended {
    margin-top: 2rem;
    width: 100%;
    // background-color: #102e44;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scroll-margin: 7rem;

    .team_title {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .first_word {
            font-weight: 400;
        }

        .orange-box {
            background: linear-gradient(to right, #f5a31a, #f5761a);
            border-radius: 0.938rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4.6rem;
            height: 4.6rem;
        }

        .teamTitle {
            text-align: center;
            font-size: 3rem;
            line-height: 3.688rem;
            font-family: 'Montserrat';
            margin-top: 1rem;
            font-weight: 700;

            .first_word {
                font-weight: 400;
            }
        }
    }

    .team_sec {
        width: 90%;
        height: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        background: white;
        // background-color: blue;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

        .team_row {
            width: 80%;
            display: flex;
            flex-direction: row;
            height: fit-content;
            margin-bottom: 1rem;
            margin-top: 1rem;
            padding: 1.25rem;
            border-radius: 0.625rem;

            img {
                border: 0.125rem solid #ffffff;
                box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.1);
                border-radius: 1.875rem;
                width: 14rem;
                background-color: #f7f7f7;
                height: 18rem;
            }

            .teamPara {
                display: flex;
                width: 80%;
                height: 100%;
                flex-direction: column;
                overflow: hidden;
                margin-left: 2rem;
                margin-top: 0.625rem;
                line-height: 70%;

                .teamName {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 2.438rem;
                    /* identical to box height */
                    color: #102e44;
                }

                p {
                    margin: 0rem;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    font-size: 1.33rem;
                    color: #f6682b;
                }

                .para {
                    margin-top: 2rem;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 150%;
                    /* or 27px */
                    color: #888888;
                }
            }
        }

        div.ant-row.teamCarouselUnit {
            width: 80%;
            height: auto;
            padding: 3%;
            background: #fefbe9;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            div.ant-carousel {
                width: 300px;
                height: 400px;
                // background-color: #102e44;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                div {
                    width: 300px;
                    height: 400px;
                    // background-color: #038b39;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    div.teamContainer {
                        width: 300px;
                        height: 400px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background: #f7f7f7;
                        border: 2px solid #ffffff;
                        // box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.1);
                        border-radius: 30px;

                        img {
                            width: 200px;
                            height: 200px;
                        }

                        .teamName {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1.5rem;
                            // line-height: 2.438rem;
                            /* identical to box height */
                            color: #102e44;
                        }

                        p {
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            font-size: 1.33rem;
                            color: #f6682b;
                        }
                    }
                }

                ul.slick-dots.slick-dots-bottom {
                    li {
                        button {
                            background-color: #0dc741;
                        }
                    }
                }
            }

            .teamDescription {
                width: 50%;
                margin-right: 5%;
                height: fit-content;
                margin-top: 2rem;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 150%;
                /* or 27px */
                color: #888888;
            }
        }
    }
}

@media screen and (min-width: 767px) {
    // Who we are
    .who {
        align-items: center;
        scroll-margin: 15vh;

        .who-elements {
            margin: 0rem 2.5rem;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
        }
        
        .who-text {
            margin-top: 3.75rem;
            display: inline-block;
            width: 50%;
            text-align: center;
            font-size: 1rem;
            line-height: 1.6;
            color: #343434;
        
            strong {
                color: #E6B31E;
            }
        }
        
        .who-element {
            flex-basis: 18%;
            text-align: center;
            align-items: flex-start;
        
            .ant-typography {
                line-height: 1.6;
                padding: auto;
                color: #343434;
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }

    #whatweoffer{
        .image-mobile {
            display: none;
        }
    }

    // Our team
    #team {
        scroll-margin: 15vh;
       
        .team-element {
            display: flex;
            align-items: center;
            max-width: 75rem;
            margin: 0 auto;
            justify-content: center;
        
            .team-image {
                margin-top: 3.125rem;
                border-radius: 50%;
                object-fit: cover;
                width: 11.25rem;
                height: 11.25rem;
            }
        
            .team-title {
                margin-top: 0.625rem;
                font-size: 1.125rem;
                font-weight: 600;
                color: #343434;
                font-family: Montserrat;
            }
        
            .designation {
                color: #343434;
                font-size: 1.25rem;
                line-height: 1.25rem;
            }
        
            .text {
                flex-basis: auto;
                max-width: 68.75rem;
                color: #343434;
                font-size: 1rem !important;
                text-align: left;
                font-weight: 400;
                white-space: pre-line;
                margin-left: 3.75rem;
            }
            
        }
    }

    #updates {
        .update-odd-card,
        .update-even-card {

            .update-image {
                padding-left: 15;
                height: 40vh;
                width: 40vw;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .who {
        display:flex;
        justify-content:center;
        flex-direction:column;
        margin-left:0.938rem;
        margin-right:0.938rem;
        scroll-margin: 11vh;
        .who-text {
            strong {
                color: #E6B31E;
            }
        }

        .who-element {
            .ant-typography {
                line-height: 1.6;
                padding: auto;
                color: #343434;
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }

    .banner {
    background-image: url('../../assets/images/samparkbindu 3.png');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 12.5rem;
    position: relative;
    top:5rem;
    margin-bottom:6.25rem;

    .alertUpdate{
        top:-12.5rem;
        margin-bottom: 1rem;
        z-index: 0;
        .wrapper{
            overflow: hidden;
            .dynamic-txts{
                display:flex;
                flex-direction:column;
                justify-content:flex-start;
                overflow: hidden;
                top:0.688rem;
                li{
                    left:-2.5rem;
                    width:100vw;
                    // background:blue;
                }
            }
        }
    }

    .bannerImage{
        height:100%;
        width:10%;
    }
    }
    
    #whatweoffer {
        display:flex;
        justify-content:center;
        flex-direction:column;
        margin-left:0.938rem;
        margin-right:0.938rem;
        scroll-margin:11vh;
        margin-top:0.625rem;

        .vikasbandhu-row, 
        .engageall-row {
            width: 95vw;
            display: flex;
            
            .image-web {
                display: none;
            }
            
            .title-row {
                width: 95vw;
                padding: 2%;
                align-self: center;
                    
                .title {
                    font-size: 1.563rem;
                    padding-left: 2%;
                }

                .image-mobile {
                    align-self: center;
                    width: 75%;
                    margin: 0 auto;
                    margin-top: 2%;
                }
            }

            .details-row {
                width: 95vw;
            }
        }
    }

    #contact {
        width: 100vw;
        height: max-content;
        background-color: #ffffff;
        margin: 0;
        margin-bottom: 15rem;
        bottom: -2vh;
        scroll-margin:13vh;

        .contact-us-title {
            font-size: 1.2rem;
            padding: 2rem;
            margin: 0;
            padding-bottom: 0rem;
        }

        .contact-us-info {
            width: max-content;
            font-size: 1rem;
            position: relative;
            left: 2rem;
            top: 35vh;
            margin-top: 50vh;
            height: max-content;

            .anticon {
                padding: 0rem;
            }

            .contact-details {
                position: relative;
                width: max-content;
                padding-left: 1rem;
                color: black;
            }

            .conatct-email {
                bottom: 3vh;
                left: 6vw;
            }

            .contact-address {
                text-align: start;
                bottom: 3vh;
                left: 6vw;
                width: 95%;
            }
        }

        .write-feedback {
            position: relative;
            right: 11rem;
            height: fit-content;
            margin-bottom: 0rem;
            padding-bottom: 0rem;
            background-color: #ffffff;

            .write-feedback-title {
                font-size: 1.2rem;
            }

            .feedback-form {
                width: 90vw;
                height: 100vw;
                background-color: #fbfbfb;
                padding: 6vw;
            }

            .custom-input {
                width: 80vw;
            }

            .vikas-btn-radius {
                width: 80vw;
            }
        }
    }

    #team{
        display:flex;
        justify-content:center;
        flex-direction:column;
        margin-left:0.938rem;
        margin-right:0.938rem;
        scroll-margin:11vh;
    }

    #updates {
        display:flex;
        flex-direction:column;
        margin-left:0.938rem;
        margin-right:0.938rem;
        scroll-margin: 15vh;
        margin-bottom: 2rem;
        .update-wrapper{
            margin-top: 0.938rem;
            .cardWrapper{
                height: 100%;
                .update-card{
                    height: 20rem; 
                    overflow-Y:auto;
                    .cardContent{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-top: 1.875rem;
                        margin-bottom: 1.875rem;
                        overflow-x: hidden;
                        .innertitle{
                            font-size: 1.563rem;
                        }
                        .cardDescription{
                            display:flex;
                            flex-direction: column;
                            justify-content:space-evenly;
                            align-items:center;
                            .innerText{
                                margin:0.438rem;
                                text-align:start;    
                            }
                            .cardImage{
                                padding-left: 15;
                                height: 20vh;
                                width: 70vw;
                                object-fit: fill;
                            }
                        }
                    } 
                }
            } 
        }
    }

    #articles{
        scroll-margin: 12vh;
        .articleContainer{
            .articleBorder{
                .articleTitle{
                    font-size: 1.063rem;
                }
                .articleContent{
                    display: flex;
                    flex-direction: column;
                    justify-content:center;
                    align-items:center;
                    // background-color: blue;
                    .articleDescription{
                        width:80vw;
                    }
                    .articleImage{
                        width:80vw;
                    }
                }
            }
        }
        
    }
}